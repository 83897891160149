/* You can add global styles to this file, and also import other style files */
body {
    // background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),url("./assets/Mask\ Group\ 30@2x.png");
    background: url("./assets/RED.png");
    background-repeat: no-repeat;
    // background-size: 100% 100%;
    background-size: cover;
    margin: 0px;
    padding: 0px;
    font-family: 'smartSans-regular', sans-serif;
    overflow-x: hidden;
    background-attachment: fixed;
}

p {
    margin: 0;
}